import React from 'react';
import { Link } from 'gatsby';
import './post-link.scss';

const PostLink = ({ post }) => (
  <li className="post-link">
    <Link to={post.frontmatter.path}>
      {post.frontmatter.title}
    </Link>
  </li>
);

export default PostLink;
