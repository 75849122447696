import React from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout/layout';
import SEO from '../components/seo';
import PostLink from '../components/post-link';
import ConvertKit from '../components/convertkit';
import * as R from 'ramda';
import './index.scss';

const PRODUCTION = process.env.NODE_ENV === 'production';

const IndexPage = data => {
  const edges = R.path(['allMarkdownRemark', 'edges'], data);
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .filter(
      edge => !PRODUCTION || (PRODUCTION && !edge.node.frontmatter.isDraft)
    )
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />);

  const pageTitle = Posts.length > 1 ? 'Essays' : 'Essay'

  return (
    <Layout>
      <SEO title="Home" keywords={['Simplified Development', 'Performance']} />
      <div className="pages">
        <h1>{pageTitle}</h1>
        <ul>{Posts}</ul>
        <h2>Where are the others?</h2>
        <p>
          {`Welcome! After 4 years as an independent contractor, I’m finally spending time documenting my findings so that others may enjoy them.`}
        </p>
        <h2>Why “Simplified?”</h2>
        <p>
          {`Because the subject of web performance is too damn complex and too damn subtle. My goal is to make it more accessible so that it becomes easier for everyone to find where to spend their energy.`}
        </p>
        <ConvertKit />
      </div>
    </Layout>
  );
};

const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "article" } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            isDraft
          }
        }
      }
    }
  }
`;

const IndexPageContainer = () => (
  <StaticQuery query={pageQuery} render={IndexPage} />
);

export default IndexPageContainer;
